<template>
    <div>
        <div class="row" v-if="listNameEditMode=='read'" style="position:relative;">
            <div class="col col-12" style="display:flex; flex-direction: column; justify-content: center;">
                <h1 @click="editList()" class="">
                    <ListIcon v-if="listIconSlug" :listIconSlug="listIconSlug" class="mr-2 d-none d-md-flex" size="large" />
                    <ListIcon v-if="listIconSlug" :listIconSlug="listIconSlug" class="mr-2 d-flex d-md-none" size="small" />
                    {{listName}}
                </h1>
                <h5 class="mt-1 mt-md-2"> by <a :href="domain + '/' +  channelName"> {{channelName}} </a> </h5>
                <!-- <h5> public participants: {{ list.public_participation_count }} </h5> -->
            </div>
            <ListSettingsModal v-if="isViewingSelf() && listId" :listId=listId :handleSuccess=refreshBrowser style="position:absolute; right:12px;"/>
        </div>

        <div class="row mt-5" v-if="listNameEditMode=='write'">
            <div class="col col-6 offset-3" align="center">
                <v-form @submit="saveListName()">
                    <v-text-field v-model="editListName" label="Edit List Name" ref="itemTextRef" @focus="$event.target.select()" />
                </v-form>
            </div>
            <div class="col col-1" v-if="isViewingSelf()">
                <CustomIcon @click="saveListName()" toolTip="Save" glyph="mdi-content-save" /> 
                <ConfirmDialog ref="confirm" />
                <v-spacer style="min-height: 15px; min-width: 15px;"/>
                <CustomIcon @click="listNameEditMode='read'; editListName=listName" toolTip="Save" color="blue" glyph="mdi-cancel" /> 
            </div>
        </div>
    </div>
</template>
<script>
import auth from "/src/utils/auth";
import api from '/src/utils/api';

export default {
    components: {
        ConfirmDialog: () => import("/src/components/ConfirmDialog"),
        CustomIcon: () => import("/src/components/CustomIcon.vue"),
        ListIcon: () => import("/src/components/ListIcon"),
        ListSettingsModal: () => import("/src/components/ListSettingsModal"),
    },
    data() {
        return {
            domain: window.location.origin,
            editListName: this.listName,
            isViewingSelf: auth.isViewingSelf,
            listNameEditMode: "read",
            // listIconSlug: null,
        };
    },
    computed: {
        listIconURL() {
            return ''; 
        }
    },
    watch: {
        // Watching for changes in the 'watchedProperty' data property
        // console.log("this.listIconSlug", this.listIconSlug);
        listIconSlug(newValue, oldValue) {
            console.log("Value changed from", oldValue, "to", newValue);
        },
    },
    mounted() {
        if (this.$route.query.listNameEditMode == 'write') {
            // Using nextTick to overcome race condition of page loading.
            this.$nextTick(() => {
                this.editList();       
            });
        }
    },
    methods: {
        editList() {
            if (auth.isViewingSelf()) {
                this.listNameEditMode = "write";

                this.$nextTick(() => {
                    // Select input field so user can quickly start typing next item.
                    this.$refs.itemTextRef.focus();
                });
            }
        },
        refreshBrowser(newListName) {
            window.location.href = "/" + this.channelName + "/" + newListName;
        },
        async saveListName() {
            // If the name wasn't modified, there's no need to save the new name.
            if (this.editListName == this.listName) {
                this.listNameEditMode = 'read';
            }
            else {
                var confirm = true;
                if(this.list.public_participation_count > 1)
                    confirm = await this.$refs.confirm.open("Change List Topic", "Changing list topic will exclude it from voting results on the original topic.");

                if ( confirm ) {
                    api.postAuthRequired('update-list', {listId:this.listId, name:this.editListName})
                    .then(() => {
                        // Since the list name is tied to the url, reload the page with the new url.
                        window.location.href = "/" + this.channelName + "/" + this.editListName;
                    });
                }
            }
        },
    },
    props: ['channelName','list','listId','listName','listIconSlug'],
};
</script>
<style scoped>
h1 {
    font-size: 1.2rem;
    display:flex;
    justify-content: center;
}

@media (min-width: 960px) {
    h1 {
        font-size: 2rem; /* Set your desired font size for desktop */
    }
}
</style>